
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  Client,
  Role,
  UpdateUserDto,
  UserDto,
} from '../../../../api/types/api';
import SnackbarModule from '../../../../store/modules/SnackbarModule';
import AdminSnackbar from '../../base/AdminSnackbar.vue';
import DeleteAllStudentsButton from '../delete-all/DeleteAllStudentsButton.vue';
import AddAccountButton from './AddAccountButton.vue';
import AddPatientAccount from './AddPatientAccount.vue';
import AddStudentAccount from './AddStudentAccount.vue';
import ClassLevelSelect from './ClassLevelSelect.vue';
import DeleteDialog from './DeleteDialog.vue';
import EditDialog from './EditDialog.vue';
import EnableFormSwitch from './EnableFormSwitch.vue';
import EvaluationButton from './EvaluationButton.vue';
import GeneralInfoPanels from './GeneralInfoPanels.vue';
import ImportDialog from './ImportDialog.vue';
import OpenImportButton from './OpenImportButton.vue';
import PrintClassEvaluationButton from './PrintClassEvaluationButton.vue';
import PrintClassListButton from './PrintClassListButton.vue';
import SetClassLevel from './SetClassLevel.vue';

@Component({
  components: {
    AdminSnackbar,
    EditDialog,
    DeleteDialog,
    EvaluationButton,
    PrintClassListButton,
    GeneralInfoPanels,
    AddPatientAccount,
    AddStudentAccount,
    ImportDialog,
    OpenImportButton,
    AddAccountButton,
    PrintClassEvaluationButton,
    EnableFormSwitch,
    SetClassLevel,
    ClassLevelSelect,
    DeleteAllStudentsButton,
  },
})
export default class StudentsTable extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() users!: UserDto[];
  @Prop() grade!: string;

  disableFormA: string[] = [];
  disableFormB: string[] = [];
  loadingId = '';
  loadSingle = false;
  loadEdit = false;
  search = '';
  dialogEdit = false;
  dialogDelete = false;
  editedIndex = -1;
  editedItem: UserDto = {
    _id: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    grade: '',
    role: Role.student,
    schoolId: 0,
    studentId: 0,
    doneA: false,
    doneB: false,
    isMiddleschool: false,
    level: 0,
  };
  defaultItem: UserDto = {
    _id: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    grade: '',
    role: Role.student,
    schoolId: 0,
    studentId: 0,
    doneA: false,
    doneB: false,
    isMiddleschool: false,
    level: 0,
  };
  showImportDialog = false;
  showAddDialog = false;

  openImportDialog() {
    this.showImportDialog = true;
  }

  closeImportDialog() {
    this.showImportDialog = false;
  }

  openAddDialog() {
    this.showAddDialog = true;
  }

  closeAddDialog() {
    this.showAddDialog = false;
  }

  get ids(): number[] {
    return this.users.map((i) => i.studentId!);
  }

  get headers(): any {
    return this.isTherapistOrParent
      ? [
          { text: 'ID', value: 'studentId' },
          { text: 'Vorname', value: 'firstname' },
          { text: 'Nachname', value: 'lastname' },
          // { text: 'Benutzername', value: 'username' },
          // {
          //   text: 'Passwort',
          //   value: 'studentPassword',
          // },
          {
            text: 'Schulstufe',
            value: 'grade',
          },
          { text: 'Test', value: 'test', sortable: false },
          // {
          //   text: 'Fertig',
          //   value: 'finished',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            text: 'Auswertung',
            value: 'evaluation',
            sortable: false,
          },
          {
            text: 'Aktionen',
            value: 'actions',
            sortable: false,
          },
        ]
      : [
          { text: 'ID', value: 'studentId' },
          { text: 'Vorname', value: 'firstname' },
          { text: 'Nachname', value: 'lastname' },
          // { text: 'Benutzername', value: 'username', sortable: false },
          // {
          //   text: 'Passwort',
          //   value: 'studentPassword',
          //   sortable: false,
          // },
          {
            text: 'Schulstufe',
            value: 'level',
            sortable: false,
          },
          { text: 'Test', value: 'test', sortable: false },
          {
            text: 'Auswertung',
            value: 'evaluation',
            sortable: false,
          },
          {
            text: 'Aktionen',
            value: 'actions',
            sortable: false,
          },
        ];
  }

  get isTherapistOrParent(): boolean {
    return (
      this.schoolModule.clientType === Client.therapy ||
      this.schoolModule.clientType === Client.parent
    );
  }

  get isParent(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  get isSchool(): boolean {
    return this.schoolModule.clientType === Client.school;
  }

  get schoolSuffix(): string {
    return this.userModule.schoolSuffix;
  }

  editItem(user: UserDto): void {
    this.editedIndex = this.users.indexOf(user);
    this.editedItem = user;
    this.dialogEdit = true;
  }

  deleteItem(user: UserDto): void {
    this.editedIndex = this.users.indexOf(user);
    this.editedItem = user;
    this.dialogDelete = true;
  }

  async printCode(user: UserDto) {
    this.loadSingle = true;
    this.loadingId = user._id;
    try {
      await this.schoolModule.getSingleCode(user._id);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.error').toString(),
        isSuccess: false,
      });
    }
    this.loadSingle = false;
    this.loadingId = '';
  }

  async deleteItemConfirm(user: UserDto): Promise<void> {
    try {
      await this.schoolModule.deleteUser(user._id);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.students.delete.success').toString(),
        isSuccess: true,
      });
      this.users.splice(this.editedIndex, 1);
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.students.delete.error').toString(),
        isSuccess: false,
      });
    }
    this.closeDelete();
  }

  close(): void {
    this.dialogEdit = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  closeDelete(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  async save(user: UpdateUserDto): Promise<void> {
    try {
      this.loadEdit = true;
      await this.schoolModule.updateUser({
        id: this.editedItem._id,
        updateUser: user,
      });
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.students.edited.success').toString(),
        isSuccess: true,
      });
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        console.log();
      }
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.students.edited.error').toString(),
        isSuccess: false,
      });
    }
    this.loadEdit = false;
    this.close();
  }

  @Emit()
  added(): void {
    console.log();
  }
}
